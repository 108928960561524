import { axiosAuthServiceApiWithCredentials } from '../index';

/* Отправить запрос на изменение Email */
export async function changeEmail(email) {
  const payload = {
    email,
  };
  const urlSegment = 'email-change';
  const response = await axiosAuthServiceApiWithCredentials.put(urlSegment, payload);
  return response.data;
}
/* /Отправить запрос на изменение Email */
