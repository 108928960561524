<template>
  <div class="change-email-view">
    <PageHeader
        class="mb-4"
        :backButtonTo="`/profile`"
    >
      Электронная почта
    </PageHeader>

    <!-- Письмо отправлено -->
    <b-alert
        show
        v-if="sended"
        variant="success"
    >
      Письмо с дальнейшими инструкциями отправлено на почту {{ form.email.value }}.
    </b-alert>
    <!-- /Письмо отправлено -->

    <b-overlay
        :show="isLoading"
        rounded="sm"
        variant="white"
        opacity="0.85"
        v-if="!sended"
    >
      <b-form @submit="onSubmitForm">
        <!-- Поле Email -->
        <b-form-group
            label="Введите новый E-mail *"
            label-for="input-email"
            :invalid-feedback="form.email.invalidFeedback"
            :state="form.email.state"
        >
          <b-form-input
              id="input-email"
              v-model="form.email.value"
              :state="form.email.state"
              type="email"
              trim
          ></b-form-input>
        </b-form-group>
        <!-- /Поле Email -->

        <!-- Ошибка сервера -->
        <b-alert show
                 v-if="serverErrorMessage"
                 variant="danger"
        >
          {{ serverErrorMessage }}
        </b-alert>
        <!-- /Ошибка сервера -->

        <b-button type="submit"
                  variant="primary"
                  block
                  :disabled="isFormButtonDisabled"
        >
          Отправить
        </b-button>
      </b-form>
    </b-overlay>

  </div>
</template>

<script>
import PageHeader from '../components/PageHeader.vue';
import { changeEmail } from '../api/esia/email';
import { getErrorTextFromErResp } from '../api/utils';

export default {
  name: 'ChangeEmail',
  components: {
    PageHeader,
  },
  computed: {
    isFormButtonDisabled() {
      return !this.form.email.value || this.form.email.value.length < 1;
    },
  },
  data() {
    return {
      isLoading: false,
      serverErrorMessage: null,
      form: {
        /* Любое поле содержит:
          - (value: str) значение;
          - (state: bool) состояние ошибки;
          - (invalidFeedback: str) текст ошибки;
        * * */
        email: {
          value: null,
          state: null,
          invalidFeedback: null,
        },
      },
      sended: false,
    };
  },
  methods: {
    async onSubmitForm(event) {
      event.preventDefault();

      // Валидация
      if (!this.form.email.value) {
        this.form.email.state = false;
        this.form.email.invalidFeedback = 'Обязательное поле!';
        return;
      }

      // Отправка на сервер
      this.isLoading = true;
      try {
        await changeEmail(this.form.email.value);
        this.isLoading = false;
        this.sended = true;
      } catch (e) {
        this.isLoading = false;
        this.serverErrorMessage = await getErrorTextFromErResp(e);
      }
    },
  },
  async mounted() {
    this.serverErrorMessage = null;
    this.sended = false;
  },
};
</script>

<style lang="scss">
.change-email-view {
  max-width: 460px;
  margin: 0 auto;
  padding: 25px 10px 50px 10px;
  text-align: left;
}
</style>
